import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import createBranchImg from '../../../src/images/figma/create-branch.png';
import createBranchDialogImg from '../../../src/images/figma/create-branch-dialog.png';
import requestReviewImg from '../../../src/images/figma/request-review.png';
import mergeDialogImg from '../../../src/images/figma/merge-dialog.png';
import requestDialogImg from '../../../src/images/figma/request-dialog.png';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ImageBox = makeShortcode("ImageBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`How can you contribute`}</h2>
    <p>{`Currently only `}<strong parentName="p">{`GitHub employees`}</strong>{` can contribute to `}<strong parentName="p">{`Primer Web`}</strong>{`, due to how Figma handles open source files.
To make changes you first need to `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=tbNCGEC2G1E"
      }}>{`create a branch`}</a>{` from Primer Web and name it `}<inlineCode parentName="p">{`@username/changes-being-made`}</inlineCode>{`.
For example: `}<inlineCode parentName="p">{`@lukasoppermann/update-button-radius`}</inlineCode>{`.`}</p>
    <p>{`When you are satisfied with the changes you made, request a review from the `}<a parentName="p" {...{
        "href": "https://github.com/primer/figma/blob/main/CONTRIBUTION.md#contribute-new-components-or-updates"
      }}>{`direct responsible individual`}</a>{` (DRI) of the file. If you are not sure who that is, reach out in the #primer-figma slack channel.`}</p>
    <p>{`Once your branch is approved, a maintainer will take care of merging and publishing the changes to the library.`}</p>
    <p>{`We use this process to make sure no accidental changes get added to the library. It also allows us to better track changes added by a branch. Think of it as a repo that does not allow direct pushes to the main branch.`}</p>
    <ImageBox caption="Select “Create branch...” from the dropdown or via the command palette" mdxType="ImageBox">
  <img width="410" alt="Screenshot showing how to create a branch in figma" src={createBranchImg} />
    </ImageBox>
    <ImageBox caption="Name your branch “@username/changes-being-made”" paddingX mdxType="ImageBox">
  <img width="293" alt="Screenshot showing the create branch dialog in figma" src={createBranchDialogImg} />
    </ImageBox>
    <ImageBox caption="When you are done request a review to get your changes merged." mdxType="ImageBox">
  <img width="730" alt="Screenshot showing the request review option in figma" src={requestReviewImg} />
    </ImageBox>
    <ImageBox caption="Select the DRI for the file to review. Reach out in the internal #primer-figma channel if you are not sure who to add." paddingX mdxType="ImageBox">
  <img width="568" alt="Screenshot showing the merge dialog in figma" src={mergeDialogImg} />
    </ImageBox>
    <ImageBox caption="Add a detailed message describe what you changed and why." paddingX mdxType="ImageBox">
  <img width="506" alt="Screenshot showing the request review text dialog in figma" src={requestDialogImg} />
    </ImageBox>
    <h2>{`Authoring components in Figma`}</h2>
    <h3>{`Naming`}</h3>
    <p>{`The name of a component and it's properties should reflected what is present in code whenever possible.`}</p>
    <p>{`For example, the button component would be named `}<inlineCode parentName="p">{`Button`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`btn`}</inlineCode>{` which is neither a utility or component name but a prior reference from CSS.`}</p>
    <p>{`Component names use `}<a parentName="p" {...{
        "href": "https://www.pluralsight.com/blog/software-development/programming-naming-conventions-explained#:~:text=PascalCase%20is%20a%20variable%20naming,%3A%20FirstName%2C%20TotalBalance%2C%20WigsWorn."
      }}>{`PascalCase`}</a>{`. For example, the action list component is named `}<inlineCode parentName="p">{`ActionList`}</inlineCode>{`. Refer to the `}<a parentName="p" {...{
        "href": "https://github.com/github/primer/blob/main/adrs/2022-02-15-spelling-of-component-names.md"
      }}>{`"Spelling of component names" ADR`}</a>{` for more context.`}</p>
    <h3>{`Layer structure`}</h3>
    <p>{`Every component that is added should have its layers named to reflect what is rendered on that layer. When describing component layer structure, Primer uses a left-to-right direction to reflect start-to-end positioning.`}</p>
    <p>{`When updating existing components, be careful with adding or removing layers that have children as this will create a breaking change for consumners.`}</p>
    <h3>{`Groups of layers`}</h3>
    <p>{`For groups of items within a component, be explicit to what is contained within a group.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`✅ Do`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`❌ Don't`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><img parentName="td" {...{
              "src": "https://github.com/user-attachments/assets/ae71d268-ecf6-429f-8ff1-6899f658f2af",
              "alt": "Do name your layers to describe what they contain"
            }}></img></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><img parentName="td" {...{
              "src": "https://user-images.githubusercontent.com/10384315/168837988-2e5d4343-c439-4319-b498-aff7c3090b92.png",
              "alt": "Don't leave layers named with abstract defaults from Figma such as Frame 403"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Text`}</h3>
    <p>{`Layer names should typically represent the type of textual information it contains. Text layers that will retain overrides when swapping to another instance or variant should be named the same.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/10384315/168841851-63b5a47a-4d43-4cad-bf3b-a76610d951bc.png",
        "alt": "Example of text layers named as title and message"
      }}></img></p>
    <p><strong parentName="p">{`Layer name examples:`}</strong>{` ButtonText, Message, Text`}</p>
    <h3>{`Actions`}</h3>
    <p>{`Layers that contain actions should have their name describe the action. `}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/user-attachments/assets/cedeb45d-af0f-43ff-8838-236facb0fff2",
        "alt": "Example with actions bundled in one layer called Action: Button options"
      }}></img></p>
    <h2>{`Variants`}</h2>
    <p>{`In Primer, variants and component properties are used to quickly select different states and/or visual options of a component. `}</p>
    <h3>{`Variant properties`}</h3>
    <p>{`When creating a new component, apply variant properties that follow existing parameters as seen in other framework implementations such as `}<a parentName="p" {...{
        "href": "https://primer.style/react/"
      }}>{`Primer React`}</a>{`. If a parameter exists but cannot be expressed in Figma (e.g. `}<inlineCode parentName="p">{`alt`}</inlineCode>{` or `}<inlineCode parentName="p">{`href`}</inlineCode>{`) it is not necessary to include it. Variant properties will always appear above component properties in the properties panel and can only be reordered with other variant properties.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/10384315/131897226-b5253552-f13f-4e85-8226-d6ca2b34d938.png",
        "alt": "Example: Avatar component in Figma"
      }}></img></p>
    <h3>{`Component properties`}</h3>
    <p>{`Certain visual options can be controlled with Figma's component properties. Apply component properties when controlling the visibility of a layer, swapping between specific instance options, or controlling the content of a text layer. Below is a list of common properties and the types of items they control.`}</p>
    <p>{`As a reminder, variant properties will always appear above component properties in the properties panel.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Component property type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`leadingVisual?`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Show/hide the leading visual which is a the start of the component (left-side)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><a parentName="td" {...{
              "href": "https://help.figma.com/hc/en-us/articles/5579474826519-Create-and-use-component-properties#h_01G2Q5GA6DEB604H2E5H5C5TA4"
            }}>{`Boolean`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Text content for "Title" layer`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><a parentName="td" {...{
              "href": "https://help.figma.com/hc/en-us/articles/5579474826519-Create-and-use-component-properties#h_01G2Q5G3FV0EQP9RZFZG7GVWEG"
            }}>{`Text`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`leadingAction`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Swap between different interactive options (such as checkbox, checkmark, bullet)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><a parentName="td" {...{
              "href": "https://help.figma.com/hc/en-us/articles/5579474826519-Create-and-use-component-properties#h_01G2Q5FYN2ADEDQ3ZSB1KKY8Z0"
            }}>{`Instance swap`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Writing properties`}</h2>
    <ul>
      <li parentName="ul">{`When writing properties, either variant or component, write them as they are seen or would be written in code. For example, the prop for an icon or avatar in the action list component is written as `}<inlineCode parentName="li">{`leadingVisual`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`When writing variants, keep the property name as its written in code with lower or camelCase and the values in lowercase.`}</li>
      <li parentName="ul">{`When indicating a boolean property add a "?". For example, the property to show/hide the title of a component is written as "title?"`}</li>
    </ul>
    <h3>{`Examples`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`label?`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` `}<inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`state`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rest`}</inlineCode>{` `}<inlineCode parentName="td">{`focus`}</inlineCode>{` `}<inlineCode parentName="td">{`hover`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`visual`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`octicon`}</inlineCode>{` `}<inlineCode parentName="td">{`avatar`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Properties table`}</h3>
    <p>{`The following table includes a list of common properties used within the Figma components in `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/Primer-Web?node-id=179%3A3870"
      }}>{`Primer Web`}</a>{`. Please note that not all components will contain all properties contained within this list. Use your best judgement as to which properties should be implemented.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included values`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A variation of the component or a specific part`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text, single select, multi-select, danger, header, item, footer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`state`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The state of the component's interactivity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`default (rest), hover, selected, disabled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size variations of the component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`small, medium, large OR pixel values`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`leading[Accessory]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The leading visual accessory of a component placed to the `}<strong parentName="td">{`left`}</strong>{` of a text label or description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icon, avatar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trailing[Accessory]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The trailing visual accessory of a component placed to the `}<strong parentName="td">{`right`}</strong>{` of a text label or description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icon, avatar, label, counter`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Descriptive text within a component; can be a toggle (boolean) set of values or a dropdown selection`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`on/off `}{`[boolean]`}{`, short/long, inline/block`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mark the component as selected; toggle (boolean) the selection marker, typically a check mark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`on/off`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Multiple components vs Multiple variants`}</h2>
    <p>{`Some components may feel connected enough to turn them into a component set in Figma. Within Primer, our main distinction between using variants or adding additional related components is to ask, "Will this change only visual styling or will this also change function/purpose of the component?".
Another question to consider is how this will effect maintainence of the component.`}</p>
    <h3>{`Examples of when to use multiple components`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/?node-id=142%3A597"
        }}>{`Alerts`}</a>{`: Banners`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/?node-id=179%3A3870"
        }}>{`Avatars`}</a>{`: Avatar and AvatarStack`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/Primer-Web?node-id=1143%3A1104"
        }}>{`Form select items`}</a></li>
    </ul>
    <h3>{`Examples of when to use variants`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/?node-id=136%3A1805"
        }}>{`Buttons`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/Primer-Web?node-id=3813%3A3"
        }}>{`Outline labels`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/Primer-Web?node-id=4287%3A7"
        }}>{`Navigation > Header`}</a></li>
    </ul>
    <h2>{`Slots`}</h2>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/10384315/137150003-9d731aac-3c26-4fdb-88d0-468e9e8399bc.png",
        "alt": "_Slot component"
      }}></img></p>
    <p>{`The `}<inlineCode parentName="p">{`_Slot`}</inlineCode>{` component is a local component to the Primer Web library and is added as a placeholder for any customizeable content where the designers have more flexibility.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/10384315/137150107-fe5ab70d-4235-44a7-bb18-daceca77c1f9.png",
        "alt": "Examples of slot placement"
      }}></img></p>
    <p>{`Areas where using this slot workflow is recommended include headers, main content, footers. Slots should be used within a container that has autolayout in order to dynamically fit its parent container. Due to certain bugs in Figma, if a `}<inlineCode parentName="p">{`_Slot`}</inlineCode>{` is replaced and does not flex, the auto layout properties will need to be manually updated.`}</p>
    <p>{`When using a component that contains slots within your own design file, create a local component that has the specific contents needed and replace the `}<inlineCode parentName="p">{`_Slot`}</inlineCode>{` with an instance of the local component. `}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/10384315/137149864-84a32917-4a33-44a9-8079-b0485edfc537.png",
        "alt": "replacing the slot component"
      }}></img>{` `}</p>
    <h2>{`Contribution checkist for component`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Changes to the component API (props, variants, etc.) are reflected in the documentation sheet`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Properties are sorted in sensible order`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`When adding a component the default variant is used`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Changes are `}<a parentName="li" {...{
          "href": "https://primer.style/design/accessibility/guidelines"
        }}>{`accessible`}</a></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Properties and behavior is as close to code as possible`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`All layers are named and don't use defaults (e.g. no `}<inlineCode parentName="li">{`Frame 23`}</inlineCode>{`)`}</li>
    </ul>
    <h3>{`Only when adding new components`}</h3>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Component name matches the name used for the code component but uses `}<a parentName="li" {...{
          "href": "https://techterms.com/definition/pascalcase"
        }}><inlineCode parentName="a">{`PascalCase`}</inlineCode></a>{` (see `}<a parentName="li" {...{
          "href": "https://github.com/github/primer/blob/main/adrs/2022-02-15-spelling-of-component-names.md#consequences"
        }}>{`"Spelling of component names"`}</a>{`)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Component is added to the `}<a parentName="li" {...{
          "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/Primer-Web?node-id=4398%3A2354"
        }}>{`overview page`}</a></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Component is in light mode`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Component uses `}<a parentName="li" {...{
          "href": "https://primer.style/design/foundations/color"
        }}>{`primitives`}</a>{` by using styles from `}<inlineCode parentName="li">{`Primer Primitives`}</inlineCode>{` Figma library`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Properties are designed according to `}<a parentName="li" {...{
          "href": "https://github.com/primer/figma/blob/main/docs/authoring-components.md#writing-properties"
        }}>{`property guidelines`}</a></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The component adheres to the `}<a parentName="li" {...{
          "href": "https://github.com/primer/figma/blob/main/docs/authoring-components.md#authoring-components-in-figma"
        }}>{`authoring components guidelines`}</a></li>
    </ul>
    <h2>{`DRIs`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Internal Figma library`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`External Figma library`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DRI`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.figma.com/file/GCvY3Qv8czRgZgvl1dG6lp/Primer-Web"
            }}>{`Primer Web (Internal)`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.figma.com/community/file/854767373644076713"
            }}>{`Primer Web (External)`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/lukasoppermann"
            }}>{`@lukasoppermann`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.figma.com/file/BJ95AjraesmRCWsKA013GS/Primer-Brand"
            }}>{`Primer Brand (Internal)`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/danielguillan"
            }}>{`@danielguillan`}</a></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      